import loadable from '@loadable/component';
import React from 'react';
import { sectionList } from '../../constants/queryFragments';
import * as Styled from './imageListColumnStyle';

const ImageListColumn = ({ sectionData }) => {
  return (
    <Styled.MainDiv>
      <Styled.RowStyle className="g-0">
        {sectionData &&
          sectionData?.listOfImagesWithPath?.map((section, index) => {
            if (section?.type && sectionList?.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section?.type}/${section?.type}`)
              );
              return (
                <Styled.ColStyle key={index} className="px-0" xs={12} sm>
                  <SectionComponent sectionData={section} />
                </Styled.ColStyle>
              );
            }
          })}
      </Styled.RowStyle>
    </Styled.MainDiv>
  );
};

export default ImageListColumn;
